import React, { useContext, useEffect, useState } from 'react';
import Layout from '@/components/layout';
import { Helmet } from 'react-helmet';
import { ImportantInformation } from '@latitude/important-information';
import { MARGIN } from '@/utils/constants';
import PageData from './data/low-rate-data.json';
import { Box } from '@latitude/box';
import { Heading4 } from '@latitude/heading';
import { PageContext } from '@/context/PageContext'; 
import appleLogo from './images/badge-app-store.svg';
import googlelogo from './images/badge-google-play.svg';
import allBrands from './images/brand-cards.webp';
import { ListNumbered } from '@latitude/list-numbered';
import HeroBanner from '@/components/lab-components/HeroBanner';
import HereToHelpSection from './_hereToHelp';
import { FeaturesSlider } from '@latitude/features-slider';
import rewardsPageDataBackUp from './data/rewards-data-backup.json';
import PromoBanner from '@/components/lab-components/PromoBanner';
import { getUrlFromEnv } from '@/utils/getUrlFromEnvUtil';

const LatitudeRewardsPage = (props) => {
 /** Contentful Data */
 const contentfulPageData = useContext(PageContext);
 const state = contentfulPageData?.updatedBody || rewardsPageDataBackUp;
 /** - END - */

 const latitudeRewardsTitle = "Get up to $300 in bonus Latitude Rewards3"
 const balanceTransferTitle = "Get 0% for 18 months on balance transfers4" //!update this title whenever contentful data changed
 // set defaults
 const promoCodeLatitudeRewards = "GHYTSERT";
 const promoCodeBalanceTransfer = "DFGVHJUY";
 const sourceCodeCodeLatitudeRewards = "IDAAB";
 const sourceCodeBalanceTransfer = "IDAAA";

 const baseApplyURL = getUrlFromEnv('low-rate-credit-card');
 const [promoApplyURLLatitudeRewards, setpromoApplyURLLatitudeRewards] = useState(baseApplyURL);
 const [promoApplyURLBalanceTransfer, setpromoApplyURLBalanceTransfer] = useState(baseApplyURL);
 
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const passedSourceCode = urlParams.get('source_code');
    const passedPromoCode = urlParams.get('promo_code');

    // Retrieve from localStorage if promoCode is not present in URL
    const sourceCode = passedSourceCode || localStorage.getItem('source_code');

    // Update localstorage only if a new code has been passed in to override previous value
    if (passedSourceCode) {
      localStorage.setItem('source_code', passedSourceCode);
    };
    
    // Construct the CTAs for code passing
    const updatedURLLatitudeRewards = new URL(promoApplyURLLatitudeRewards);
    const updatedURLBalanceTransfer = new URL(promoApplyURLBalanceTransfer);
    updatedURLLatitudeRewards.searchParams.set('promo_code', passedPromoCode || promoCodeLatitudeRewards);
    updatedURLLatitudeRewards.searchParams.set('source_code', sourceCode || sourceCodeCodeLatitudeRewards);
    updatedURLBalanceTransfer.searchParams.set('promo_code', passedPromoCode || promoCodeBalanceTransfer);
    updatedURLBalanceTransfer.searchParams.set('source_code', sourceCode || sourceCodeBalanceTransfer);
    setpromoApplyURLLatitudeRewards(updatedURLLatitudeRewards.toString());
    setpromoApplyURLBalanceTransfer(updatedURLBalanceTransfer.toString());
  }, []);

  PageData.RewardsHeaderData['urlApplyNow'] = baseApplyURL;

  //Manually append the dynamic promo url to the contentful
  if (state) {
    const promoCards = state.promoBannerData?.[2].promoCards;
    
    if (promoCards) {
      promoCards.forEach(promoCard => {
        switch (promoCard.name) {
          case latitudeRewardsTitle:
            promoCard.primaryButton.url = promoApplyURLLatitudeRewards
            break;
          case balanceTransferTitle:
            promoCard.primaryButton.url = promoApplyURLBalanceTransfer
            break;
        }
      })
    }
  }

  return (
    <Layout location={props.location}>
      <Helmet>
        <link
          rel="canonical"
          href="https://www.latitudefinancial.com.au/credit-cards/low-rate/latitude-rewards/"
        />
        <title>{props.title || 'Latitude Rewards | Latitude Financial'}</title>
        <meta name="description" content="Latitude Low Rate Rewards" />
      </Helmet>
      <main className="navigation-spacer">
        {state?.heroBannerData?.[0] && (
          <HeroBanner {...state?.heroBannerData[0]} />
        )}


        {state?.promoBannerData?.[0] && (
          <PromoBanner {...state?.promoBannerData[0]} />
        )}

        {state?.promoBannerData?.[1] && (
          <PromoBanner {...state?.promoBannerData[1]} />
        )}

        <Box.Section id="all-brands" isBodyCopy backgroundColor="#C7ECFF">
          <Heading4 css={`text-align: center;`} marginTop={MARGIN.M24}>All your favourite brands are here</Heading4>
          <img src={allBrands} width="100%" alt="All your favourite brands are here" ></img>
        </Box.Section>

        <Box 
          backgroundColor='#C7ECFF'
          css={`
            padding-bottom: 40px;
            @media (min-width: 768px) {
              padding-bottom: 72px 0;
            }
          `}
        >
          <Box id="redeem-rewards" isBodyCopy backgroundColor="#C7ECFF" css={`
            margin: 0 auto;
            max-width: 690px;
          `}>
            <Heading4 marginTop={0}>How to redeem your Latitude Rewards</Heading4>
            <ListNumbered
              counterBgColor="#0046AA"
              css={`
                
                `}
              data={[
                '<span><b>Get 3% back in Latitude Rewards</b> <span>when you set up recurring payments direct with participating utilities, telco, and streaming services. T&Cs apply.</span></span>',
                `<div><b>Download or open the Latitude App</b> or log onto the Latitude Service Centre.</div><br />`,
                `<div>
                    <a
                      noStyle
                      href="https://itunes.apple.com/app/apple-store/id1373059866?pt=118165058&ct=InfPayOpt0319&mt=8"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="d-inline link-noline"
                      data-trackid="payment-options-itunes-app-link"
                      trackEventData={{
                        label: LFS_APP.APP_STORE
                      }}
                    >
                      <img src=${appleLogo} height="48px" alt="app store">  
                    </a>
                    <span>&#32;&#32;&#32;</span>
                    <a 
                      href="https://play.google.com/store/apps/details?id=com.latitudefinancial.latitudeapp&referrer=utm_source%3DInfPayOpt0319"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="d-inline"
                      data-trackid="payment-options-googleplay-app-link"
                      trackEventData={{
                        label: LFS_APP.GOOGLE_PLAY
                      }}
                    >
                      <img src=${googlelogo} height="48px" alt="play store">  
                    </a>
                  </div>`,
                '<span><b>Click on the ‘Benefits’ tab.</b></span>',
                '<span><b>Click on the `Redeem now` button</b> under the Latitude Rewards Program.</span>',
                '<span><b>Use your Latitude Rewards to redeem an e-gift card at your preferred participating retailer.</b>You can also use your participating Latitude credit card to  purchase a portion or the total value of an e-gift card , allowing you to save your Latitude Rewards for a later redemption.  Latitude Rewards expire 18 months from the date they have been issued.</span>',
                '<span><b>Follow the prompts to check out.</b></span>'
              ]}
              separator="none"
            />
          </Box>
        </Box>

        <Heading4
          css={`
            background-color: #E6E6E6;
            padding-top: 56px;
            margin: 0;
          `}
          align='center'
          isResponsive
        >
          New here? Pick an application offer that works best for you.
        </Heading4>
        {state?.promoBannerData?.[2] && (
          <PromoBanner {...state?.promoBannerData[2]} />
        )}

        {state?.featureSliderData?.[0] && (
          <FeaturesSlider
            key={state?.featureSliderData?.[0].heading}
            heading={state?.featureSliderData?.[0].heading}
            id="why-us"
            className="why-choose bg-f8f8f8"
            data={state?.featureSliderData?.[0].featureCards}
            subheading={state?.featureSliderData?.[0].description}
            css={`
              && {
                padding-bottom: 0;
              }
            `}
          />
        )}
        {state?.featureSliderData?.[1] && (
          <FeaturesSlider
            key={state?.featureSliderData?.[1].heading}
            heading={state?.featureSliderData?.[1].heading}
            id="why-us-2"
            className="why-choose bg-f8f8f8"
            data={state?.featureSliderData?.[1].featureCards}
            subheading={state?.featureSliderData?.[1].description}
            css={`
              && {
                padding-top: 0;
              }
            `}
          />
        )}

        <HereToHelpSection id="here-to-help" />
        <ImportantInformation
          {...state?.importantInfoData?.[0]}
        />
      </main>
    </Layout>
  );
};

export default LatitudeRewardsPage;
